/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.payment-subsection {
  margin-left: 64px;
  margin-bottom: 32px;
}
.mobile .payment-subsection {
  margin-left: 16px;
}
.payment-subsection-full {
  margin-bottom: 32px;
}
.payment-row {
  margin-bottom: 12px;
}
.payment-row > .title {
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  margin-bottom: 8px;
  text-decoration: underline;
}
.payment-row label {
  color: #1890ff;
}
.mobile .payment-row label {
  margin-bottom: 4px;
}
.payment-row .help {
  color: #8c8c8c;
  margin-top: 2px;
  font-size: 0.95em;
}
.payment-detail .payment-row {
  margin-bottom: 16px;
}
.payment-detail .payment-row label {
  display: inline-block;
  width: 130px;
  text-align: right;
  margin-right: 4px;
}
.payment-detail .payment-row .payment-input {
  display: inline-block;
}
.mobile .payment-detail .payment-row .payment-input.payment-status-input::v-deep .ant-radio-wrapper {
  display: block;
}
.payment-detail .payment-row .payment-value {
  display: inline-block;
  color: #14837B;
}
.payment-detail .payment-row .help {
  margin-left: 124px;
}
.payment-detail .payment-row.full > label {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 4px;
}
.payment-divider {
  margin-bottom: 24px;
}
.summary-1 {
  font-size: 1.1em;
  font-weight: 600;
}
.summary-2.payment-row {
  font-size: 1.1em;
  font-weight: 600;
  background-color: #40a9ff;
  padding: 8px;
  max-width: 600px;
  margin-left: -16px;
  border-radius: 4px;
}
.summary-2.payment-row label,
.summary-2.payment-row .payment-value {
  color: #fff;
}
